import React from 'react';

import {
  capitalizeFirstLetter,
  useCheckoutStore,
  useLanguageStore,
  useLocationStore,
  useMenuStore,
  useNavigation,
  useParams,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Background, Overlay } from '@components';

import { CustomButton } from '../../../../components/CustomButton/CustomButton';

import styles from './AllergensModal.module.scss';
import { observer } from 'mobx-react';
import { ProductItem } from 'plugins/CheckoutPlugin/types/CheckoutTypes';

interface AllergensModalProps {
  id?: string;
}

export const AllergensModal: React.FC<AllergensModalProps> = observer(
  ({ id }) => {
    const { isLg } = useWindowSize();
    const locationStore = useLocationStore();
    const languageStore = useLanguageStore();
    const CheckoutStore = useCheckoutStore();
    const MenuStore = useMenuStore();
    const { t } = useTranslation();
    const navigation = useNavigation();
    const params = useParams<{ id: string }>();

    const itemData: ProductItem =
      CheckoutStore.currentOrder[params.id] ||
      ((MenuStore.items.find(
        (item) => item.id.toString() === (params.id || id),
      ) || {}) as unknown as ProductItem);

    const allergens = itemData.allergens;

    const onClose = () => {
      navigation.goBack();
    };

    const HeaderDefault = (
      <div className={styles.allergenModalTitle}>{t('allergens.title')}</div>
    );

    const renderSubtext = (subtext: string) =>
      subtext && <div className={styles.allergenModalSubtext}>{subtext}</div>;

    const visibleAllergens = allergens.map((allergenId) =>
      locationStore.allergensList.find(
        (allergen) => allergen.id === allergenId,
      ),
    );

    return (
      <Overlay width={480} testId="allergens-modal" onClickOutside={onClose}>
        <Background secondary={isLg} className={styles.allergenModal}>
          {HeaderDefault}
          {renderSubtext(t('allergens.subText'))}
          <div
            className={styles.allergenModalContainer}
            data-test-id="allergens-modal"
          >
            {visibleAllergens.map((item, index) => (
              <div
                key={item.name + index}
                className={styles.allergenModalContainerItem}
              >
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    filter:
                      'brightness(0) saturate(100%) invert(5%) sepia(6%) saturate(2383%) hue-rotate(222deg) brightness(92%) contrast(87%)',
                  }}
                  className={styles.allergenModalContainerItemAbb}
                />
                <div className={styles.allergenModalContainerItemName}>
                  {item.translations.find(
                    (el) => el.language === languageStore.selectedValue,
                  )?.text || item.name}
                </div>
              </div>
            ))}
          </div>
          <CustomButton
            text={capitalizeFirstLetter(t('confirm.button').toLowerCase())}
            handleButtonClick={onClose}
            className={styles.allergenModalCustomButton}
          />
        </Background>
      </Overlay>
    );
  },
);
