import React from 'react';

import { Text, PrimaryButton } from '@components';

import styles from './TimePickerMobile.module.scss';
import { TimePickerScroll } from './TimePickerScroll/TimePickerScroll';
import {
  type TimePickerDates,
  type TimePickerTimes,
  type TimePickerValues,
  type TimePickerOnChangeParameters,
} from '../types';

type TimePickerMobileProps = {
  handleChange: (
    name: TimePickerOnChangeParameters['name'],
    value: TimePickerOnChangeParameters['value'],
  ) => void;
  onSubmit: (time: string) => void;
  isAsap: boolean;
  dates: TimePickerDates[];
  locationTimesList: TimePickerTimes[];
  values: TimePickerValues;
};

export const TimePickerMobile: React.FC<TimePickerMobileProps> = ({
  handleChange,
  onSubmit,
  isAsap,
  dates,
  locationTimesList,
  values,
}) => {
  return (
    <div className={styles.timeModalContainerForm}>
      <TimePickerScroll
        optionGroups={{ date: dates, time: locationTimesList }}
        valueGroups={values}
        onChange={handleChange}
        height={250}
        itemHeight={40}
        isLoading={locationTimesList.length === 0}
      />
      <PrimaryButton
        disabled={
          locationTimesList.length === 0 && dates.length === 0 && !isAsap
        }
        className={styles.primaryButton}
        testId="time-selection"
        onClick={() => onSubmit(values.time)}
      >
        <Text value="timeSelection.setTimeButton" />
      </PrimaryButton>
    </div>
  );
};
