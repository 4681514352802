import { action, computed, makeAutoObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import {
  isPast,
  PaymentConstants,
  PaymentMethodsValue,
  setDateTime,
  StorageService,
  toUTCDateFormat,
} from 'mycheck-core';

import { CartStoreService } from '../../../core/core/service/CartStoreService';
import { TipType } from '../Constants';
import {
  Coupon,
  CurrentOrder,
  HttpCheckoutApiRequest,
  HttpCheckoutApiResponse,
  ICheckoutApi,
  ProductItem,
} from '../types/CheckoutTypes';

export const CHECKOUT_STORE = 'CHECKOUT_STORE';

export class CheckoutStore {
  @observable isFetching = false;
  @observable @persist('object') currentOrder: CurrentOrder = {};
  @observable @persist uuid = '';
  @observable @persist comment = '';
  @observable @persist paymentMethod = '';
  @observable @persist firstName = '';
  @observable @persist lastName = '';
  @observable @persist phoneNumber = '';
  @observable @persist email = '';
  @observable @persist tipType: TipType = TipType.PERCENTAGE;
  @observable @persist tipAmount = 0;
  @observable @persist tipPrice = 0;
  @observable @persist deliveryMethod = '';
  @observable isGrace = false;
  @observable isOnGraceTime = false;
  @observable numberOfDiners = '';
  @observable tableNumber = '';
  @observable roomNumber = '';
  @observable roomToken = '';
  @observable paymentMethodId: number = null;
  @observable paymentMethodCreditTypeFull = '';
  @observable fingerprint: Record<string, unknown> = null;
  @observable encryptedCvv = '';
  @observable @persist orderNumber: string = null;
  @observable @persist paymentId: number | null = null;
  @observable @persist confirmationNumber: string = null;
  @observable @persist timestamp: number = null;
  @observable checkoutTime = '';
  @observable coupon: Coupon = undefined;
  private checkoutApi: ICheckoutApi;

  constructor(checkoutApi: ICheckoutApi) {
    this.checkoutApi = checkoutApi;

    makeAutoObservable(this);
  }

  @computed
  get hasCartItems() {
    return Object.keys(this.currentOrder).length > 0;
  }

  @computed
  get numberOfCurrentOrderItems() {
    return Object.keys(this.currentOrder).length;
  }

  @computed
  get currentOrderItemsTotalQuantity() {
    return Object.values(this.currentOrder).reduce(
      (acc: number, order: ProductItem) => acc + order.quantity,
      0,
    );
  }

  @computed
  get totalPriceBeforeTaxesAndTips() {
    const allModifiersIds = Object.values(this.currentOrder)
      .map((item) => item.modifiers)
      .map((el) => Object.values(el))
      .flat(Infinity);
    const currentOrderItemsPrices = Object.values(this.currentOrder)
      .map(
        (item) =>
          Object.keys(item.modifiers)
            .map(
              (modId) =>
                item.modifierGroups.find(
                  (modGroup) => modGroup.id === Number(modId),
                )?.modifiers,
            )
            .flat()
            .filter((mod) => allModifiersIds.includes(mod.pos_unique_id))
            .reduce((acc, mod) => acc + mod.price, item.price) * item.quantity,
      )
      .reduce((acc, price) => acc + price, 0);

    return currentOrderItemsPrices;
  }

  @action
  clearCheckout = (withoutCurrentOrder = false): void => {
    this.isFetching = false;
    this.isGrace = false;
    this.isOnGraceTime = false;
    this.currentOrder = withoutCurrentOrder ? this.currentOrder : {};
    this.uuid = '';
    this.comment = '';
    this.paymentMethod = '';
    this.phoneNumber = '';
    this.numberOfDiners = '';
    this.tipAmount = 0;
    this.tipType = TipType.PERCENTAGE;
    this.orderNumber = '';
    this.paymentId = null;
    this.confirmationNumber = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.timestamp = null;
    this.removeCoupon();
  };

  @action
  clearCheckoutWithTime = (): void => {
    this.clearCheckout();
    this.checkoutTime = '';
  };

  @action
  clearRoomTableParams = (): void => {
    this.roomNumber = '';
    this.tableNumber = '';
  };

  @action
  clearOrderId = (): void => {
    this.uuid = uuidv4();
  };

  @action
  restoreCurrentOrder = (value: CurrentOrder): void => {
    this.currentOrder = value;
  };

  @action
  setComment = (comment: string): void => {
    this.comment = comment;
  };

  @action
  setFirstName = (firstName: string): void => {
    this.firstName = firstName;
  };

  @action
  setLastName = (lastName: string): void => {
    this.lastName = lastName;
  };

  @action
  setEmail = (email: string): void => {
    this.email = email;
  };

  @action
  setPhoneNumber = (phoneNumber: string): void => {
    this.phoneNumber = phoneNumber;
  };

  @action
  setPaymentMethod = (paymentMethod: string): void => {
    this.paymentMethod = paymentMethod;
  };

  @action
  setRoomNumber = (roomNumber: string): void => {
    this.roomNumber = roomNumber;
  };

  @action
  setRoomToken = (roomToken: string): void => {
    this.roomToken = roomToken;
  };

  @action
  setTableNumber = (tableNumber: string): void => {
    this.tableNumber = tableNumber;
  };

  @action
  setNumberOfDiners = (numberOfDiners: string): void => {
    this.numberOfDiners = numberOfDiners;
  };

  @action
  setTip = (tip: number, tipType: TipType = TipType.PERCENTAGE): void => {
    this.tipAmount = tip;
    this.tipType = tipType;
  };

  @action
  setTipPrice = (tipPrice: number): void => {
    this.tipPrice = tipPrice;
  };

  @action
  setDeliveryMethod = (deliveryMethod: string): void => {
    this.deliveryMethod = deliveryMethod;
  };

  @action
  setOrderItem = (product: ProductItem): void => {
    const editId = product.editId || uuidv4();
    this.currentOrder[editId] = { ...product, editId };
    CartStoreService.saveToStore(this.currentOrder);
  };

  @action
  removeOrderItem = (editId: string): void => {
    delete this.currentOrder[editId];
    CartStoreService.saveToStore(this.currentOrder);
  };

  @action
  removeAllOrderItems = (): void => {
    this.currentOrder = {};
    CartStoreService.clearStore();
  };

  @action
  setOrderNumber = (number: string): void => {
    this.orderNumber = number;
  };

  @action
  setConfirmationNumber = (number: string): void => {
    this.confirmationNumber = number;
  };

  @action
  setPaymentMethodId = (id: string): void => {
    this.paymentMethodId = parseInt(id);
  };

  @action
  setPaymentMethodCreditTypeFull = (value: string): void => {
    this.paymentMethodCreditTypeFull = value;
  };

  @action
  setFingerprint = (json: Record<string, unknown>): void => {
    this.fingerprint = json;
  };

  @action
  setEncryptedCvv = (cvv: string): void => {
    this.encryptedCvv = cvv;
  };

  @action
  // TODO type time argument properly throughout the app
  setSelectedCheckoutTime = (time: string): void => {
    if (!!time) {
      this.checkoutTime = setDateTime(
        isPast(time) ? moment.utc().format() : time,
      );
    } else {
      this.checkoutTime = null;
    }
  };

  @action
  setIsGrace = (isGrace: boolean): void => {
    this.isGrace = isGrace;
  };

  @action
  setIsOnGraceTime = (isOnGraceTime: boolean): void => {
    this.isOnGraceTime = isOnGraceTime;
  };

  commonCartObject = (experienceId: string, businessId: string) => ({
    id: this.uuid,
    experience_id: experienceId,
    business_id: businessId,
    items: Object.values(this.currentOrder).map((product) => ({
      pui: product.pos_unique_id,
      quantity: product.quantity,
      comment: product.comment.replaceAll('%', '&#x00025;'),
      modifiers: product.modifierGroups
        .map((e) => e.id)
        .map((e) => product.modifiers[e] || [])
        .reduce((a, b) => [...a, ...b], [])
        .map((ele) => ({
          comment: '',
          pui: ele,
          quantity: 1,
        })),
    })),
    comment: this.comment,
  });

  checkoutCartObject = (
    checkoutAt: string,
    isTipAvailable: boolean,
    kioskEmailReceipt?: string,
  ) => ({
    checkout_at: checkoutAt,
    created_at: toUTCDateFormat(),
    details: {
      email: kioskEmailReceipt || this.email,
      name: `${this.firstName}${this.lastName ? ` ${this.lastName}` : ''}`,
      first_name: this.firstName,
      last_name: this.lastName,
      ...(this.roomNumber ? { room_number: this.roomNumber } : {}),
      ...(this.tableNumber ? { table_number: this.tableNumber } : {}),
      ...(this.numberOfDiners ? { number_of_diners: this.numberOfDiners } : {}),
      ...(this.phoneNumber ? { phone: this.phoneNumber } : {}),
    },
    receipt: { receive_by: [] },
    ...(isTipAvailable
      ? { tip: { amount: this.tipAmount.toFixed(2), type: this.tipType } }
      : {}),
  });

  checkoutPaymentObject = (): Partial<
    HttpCheckoutApiRequest['checkoutCart']
  > => {
    switch (this.paymentMethod) {
      case PaymentConstants.PaymentMethodsValue.PAY_IN_PERSON:
        return {
          payment: { type: PaymentConstants.PaymentMethodsValue.PAY_IN_PERSON },
        };

      case PaymentConstants.PaymentMethodsValue.WALLET:
        return {
          payment: {
            type: PaymentConstants.PaymentMethodsValue.WALLET,
            payment_method_id: this.paymentMethodId,
            issuer_name: this.paymentMethodCreditTypeFull,
            ...(this.fingerprint ? { fingerprint: this.fingerprint } : {}),
            ...(this.encryptedCvv ? { encrypted_cvv: this.encryptedCvv } : {}),
          },
        };

      case PaymentConstants.PaymentMethodsValue.ROOM:
        return {
          payment: {
            type: PaymentConstants.PaymentMethodsValue.ROOM,
            ...(this.roomToken ? { room_charge_token: this.roomToken } : {}),
          },
        };

      case PaymentConstants.PaymentMethodsValue.EMV:
        return {
          payment: {
            type: PaymentConstants.PaymentMethodsValue.EMV,
          },
        };
    }
  };

  checkoutTotalCalculations = (total = false) => ({
    total_calculations: total,
  });
  checkoutCouponCode = (couponCode: string): { coupon_code: string } => ({
    coupon_code: couponCode,
  });

  @action
  deleteItemAfterError = (itemsNotInStock = []): void => {
    const outOfStockItems = itemsNotInStock.map((e) => e.id);
    const outOfStockItemsSerial = itemsNotInStock.map((e) => e.serial_id);
    this.currentOrder = Object.keys(this.currentOrder)
      .filter(
        (key) =>
          !outOfStockItems.includes(this.currentOrder[key].pos_unique_id) &&
          !outOfStockItems.includes(this.currentOrder[key].serial_id) &&
          !outOfStockItemsSerial.includes(
            this.currentOrder[key].pos_unique_id,
          ) &&
          !outOfStockItemsSerial.includes(this.currentOrder[key].serial_id),
      )
      .map((key) => ({ [key]: this.currentOrder[key] }))
      .reduce((a, b) => ({ ...a, ...b }), {});

    CartStoreService.saveToStore(this.currentOrder);
  };

  @action
  updateOrCreateCheckout = async ({
    accessToken,
    experienceId,
    businessId,
    totalCalculation = false,
    couponCode = undefined,
  }): Promise<any> => {
    this.isFetching = true;
    this.timestamp = Date.now();

    const method = this.uuid
      ? this.checkoutApi.putUpdateCart
      : this.checkoutApi.postCreateCart;
    const checkoutAt = this.uuid
      ? toUTCDateFormat(this.checkoutTime)
      : undefined;
    if (!this.uuid) {
      this.uuid = uuidv4();
    }

    try {
      const cartObject = this.commonCartObject(experienceId, businessId);
      const response = await method(
        {
          ...cartObject,
          ...this.checkoutTotalCalculations(totalCalculation),
          ...this.checkoutCouponCode(couponCode),
          checkout_at: checkoutAt,
        },
        accessToken,
      );

      if (response.cart.status === 'REJECTED') {
        throw new Error();
      }

      if (response.cart.coupon) {
        StorageService.setValue(
          StorageService.STORAGE_KEYS.COUPON,
          JSON.stringify(response.cart.coupon),
        );
      } else {
        this.removeCoupon();
      }

      const summary = response.cart.summery;
      const service_charges = response.cart.service_charges;
      const cartId = response.cart.id;
      const taxes = response.cart.taxes;
      const hash = response.cart.hash;
      const isPendingCalculations =
        response.cart.status === 'PENDING_CALCULATIONS';
      return {
        cartId,
        taxes,
        hash,
        summary: {
          ...summary,
          service_charges,
          itemsQuantity: cartObject.items?.length,
        },
        isPendingCalculations,
      };
    } catch (error) {
      const err = error as Error & { items_not_in_stock: unknown[] };
      if (
        err.message === 'CART_RACE_CONDITIONS' ||
        err.message === 'CART_EXPERIENCE_OR_BUSINESS_CAN_NOT_BE_CHANGED'
      ) {
        this.removeCoupon();
        this.uuid = uuidv4();
        return await this.updateOrCreateCheckout({
          accessToken,
          experienceId,
          businessId,
          totalCalculation,
        });
      }

      if (err.message === 'CART_ITEM_OUT_OF_STOCK') {
        this.deleteItemAfterError(err.items_not_in_stock);
      }

      if (err.message === 'CART_INVALID_COUPON_CODE') {
        this.removeCoupon();
      }

      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  cartCheckout = async ({
    accessToken,
    experienceId,
    businessId,
    checkoutAt,
    isTipAvailable,
    isCoupon,
    kioskDeviceId,
    kioskEmailReceipt,
  }: {
    accessToken: string;
    // TODO fix any types,right now it cause type errors througout app
    experienceId: any;
    businessId: any;
    checkoutAt: string;
    isTipAvailable: boolean;
    isCoupon: boolean;
    kioskDeviceId?: string;
    kioskEmailReceipt?: string;
  }): Promise<{
    is3DSecure: boolean;
  }> => {
    try {
      const response = await this.checkoutApi.postCheckoutCart(
        {
          ...this.commonCartObject(experienceId, businessId),
          ...this.checkoutCartObject(
            checkoutAt,
            isTipAvailable,
            kioskEmailReceipt,
          ),
          ...this.checkoutPaymentObject(),
          ...this.checkoutTotalCalculations(false),
          ...this.checkoutCouponCode(isCoupon ? this.coupon?.code : undefined),
          ...(this.paymentMethod === PaymentMethodsValue.EMV &&
            kioskDeviceId && {
              device_id: kioskDeviceId,
            }),
        },
        accessToken,
      );
      this.orderNumber = response.order.id?.toString();
      this.confirmationNumber = response.order.check_number?.toString();
      this.paymentId = response.order.payments.find(
        (e) => e.status === 'AWAITING_CONFIRMATION',
      )?.id;

      return {
        is3DSecure: !!this.paymentId,
      };
    } catch (error) {
      if (error instanceof Error) {
        if (
          error.message === 'CART_RACE_CONDITIONS' ||
          error.message === 'CART_EXPERIENCE_OR_BUSINESS_CAN_NOT_BE_CHANGED'
        ) {
          this.uuid = uuidv4();
          return await this.cartCheckout({
            accessToken,
            experienceId,
            businessId,
            checkoutAt,
            isTipAvailable,
            isCoupon,
          });
        }
      }
    }
  };

  @action
  confirm3DSecure = async (transactionId: number): Promise<void> => {
    await this.checkoutApi.confirm3DSecure(
      this.orderNumber,
      this.paymentId,
      transactionId,
    );
  };

  @action
  cancelTransaction = async (): Promise<void> => {
    await this.checkoutApi.cancelTransaction(this.orderNumber, this.paymentId);
  };

  @action
  validateRoom = async (
    bid: number,
    accessToken: string,
    isRoom: boolean,
  ): Promise<HttpCheckoutApiResponse['iRoomValidation'] | Error> => {
    this.isFetching = true;
    try {
      if (isRoom) {
        return await this.checkoutApi.roomValidation(
          {
            roomNumber: this.roomNumber,
            lastName: this.lastName?.trim() || '',
            bid,
          },
          accessToken,
        );
      }

      return await this.checkoutApi.roomValidity(
        {
          roomNumber: this.roomNumber,
          lastName: this.lastName?.trim() || '',
          bid,
        },
        accessToken,
      );
    } catch (err) {
      return err as Error;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  removeUnavailableItems = (items): boolean => {
    const currentOrderItems = Object.values(this.currentOrder);
    const availableItems = items.map((i) => i.id);

    let hasSomeUnavailableItems = false;
    this.currentOrder = currentOrderItems
      .filter((coi) => {
        const result = availableItems.includes(coi.id);
        if (!result) {
          hasSomeUnavailableItems = true;
        }

        return result;
      })
      .reduce(
        (a, b) => ({
          ...a,
          [b.editId]: b,
        }),
        {},
      );

    CartStoreService.saveToStore(this.currentOrder);

    return hasSomeUnavailableItems;
  };

  // the total number of menu items added to the order
  @action
  getOrderItemNumber(itemId: number): number {
    const result = Object.entries(this.currentOrder).filter(
      ([_key, value]) => value.id === itemId,
    );
    return result ? result.reduce((acc, val) => acc + val[1].quantity, 0) : 0;
  }

  @action
  getCoupon = (): Coupon => {
    const _coupon = JSON.parse(
      StorageService.getValue(StorageService.STORAGE_KEYS.COUPON),
    );
    this.coupon = _coupon;
    return _coupon;
  };

  @action
  removeCoupon = (): void => {
    this.coupon = undefined;
    StorageService.removeValue(StorageService.STORAGE_KEYS.COUPON);
  };
}
