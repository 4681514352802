import classnames from 'classnames';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import styles from '../MenuPage.module.scss';
import { MenuPageContext } from './MenuPageContext';
import { useLocationStore, useMenuStore, useWindowSize } from 'mycheck-core';
import { Loader } from '@components';
import { CookiePolicy } from 'plugins/LocationPlugin/components/CookiePolicy/CookiePolicy';
import { AlcoholModal } from 'plugins/MenuPlugin/components/AlcoholModal/AlcoholModal';
import { ViewMenuModal } from 'plugins/MenuPlugin/components/ViewMenuModal/ViewMenuModal';

export interface MenuPageContainerProps extends PropsWithChildren {}

const MenuPageContainer: React.FC<MenuPageContainerProps> = ({ children }) => {
  const {
    config,
    selectedProduct,
    isLoading,
    viewMenuModalOpen,
    setViewMenuModalOpen,
  } = useContext(MenuPageContext);
  const LocationStore = useLocationStore();
  const MenuStore = useMenuStore();
  const { isLg } = useWindowSize();

  const handleViewMenuModalDesktop = useCallback(
    () =>
      setViewMenuModalOpen(
        LocationStore.selectedExperience &&
          !LocationStore.selectedExperience.settings.checkout.is_active,
      ),
    [LocationStore.selectedExperience, setViewMenuModalOpen],
  );

  const handleViewMenuModalMobile = useCallback(() => {
    if (
      !MenuStore.viewOnlyShowed &&
      LocationStore.selectedExperience &&
      !LocationStore.selectedExperience.settings.checkout.is_active
    ) {
      setViewMenuModalOpen(true);
      MenuStore.setViewOnlyShowed(true);
    } else {
      setViewMenuModalOpen(false);
    }
  }, [LocationStore.selectedExperience, MenuStore, setViewMenuModalOpen]);

  useEffect(() => {
    if (isLg) {
      handleViewMenuModalDesktop();
    } else {
      handleViewMenuModalMobile();
    }
  }, [
    LocationStore.selectedExperience,
    handleViewMenuModalDesktop,
    handleViewMenuModalMobile,
    isLg,
  ]);

  return (
    <div
      className={classnames({
        [styles.root]: isLg,
        [styles.menuPage]: !isLg,
      })}
    >
      {config.isCookiePolicyVisible && <CookiePolicy />}
      {children}
      {selectedProduct && <AlcoholModal />}
      {!isLoading && viewMenuModalOpen && <ViewMenuModal />}
      {isLoading && <Loader />}
    </div>
  );
};

MenuPageContainer.displayName = 'MenuPageContainer';

export default MenuPageContainer;
