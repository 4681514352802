import { createContext } from 'react';
import { MenuItem } from 'types/GenericTypes';

type ConfigType = {
  isCookiePolicyVisible: boolean;
  viewModeBox: boolean;
};

export type MenuPageContextType = {
  setSelectedTab: (id: number) => void;
  handleProductClick: (item: MenuItem) => Promise<void>;
  handleSubmitAlcoholModal: () => Promise<void>;
  handleCloseAlcoholModal: () => void;
  setViewMenuModalOpen: (isOpen: boolean) => void;
  selectedTab: number;
  selectedProduct: MenuItem | null;
  viewMenuModalOpen: boolean;
  isLoading: boolean;
  config: ConfigType;
  headerTitle: string;
};

export const MenuPageContext = createContext<MenuPageContextType | null>(null);
