import React from 'react';
import { KioskModal } from '../common/Modal/KioskModal';
import {
  useCheckoutStore,
  useKioskReactNativeEvent,
  useNavigation,
  useTranslation,
} from 'mycheck-core';
import { KioskPostMessageEvent } from 'types/KioskTypes';

export const KioskStartOver = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const CheckoutStore = useCheckoutStore();
  const { sentReactNativeEvent } = useKioskReactNativeEvent(
    KioskPostMessageEvent.RedirectToLandingPage,
    undefined,
    () => CheckoutStore.removeAllOrderItems(),
  );

  const onClose = () => {
    navigation.push('/menu');
  };

  const startOver = () => {
    sentReactNativeEvent();
    onClose();
  };

  return (
    <KioskModal
      onClose={onClose}
      primaryButtonText={t('kiosk.kioskStartOverModal.primaryButton')}
      secondaryButtonText={t('kiosk.kioskStartOverModal.secondaryButton')}
      title={t('kiosk.kioskStartOverModal.title')}
      secondaryButtonClick={onClose}
      primaryButtonClick={startOver}
      testId="kiosk-start-over-modal"
      withCenterTitle
    >
      {t('kiosk.kioskStartOverModal.content')}
    </KioskModal>
  );
};
