import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  GaService,
  MyCheckApp,
  textEllipsis,
  useMenuStore,
  useNavigation,
  useWindowSize,
} from 'mycheck-core';

import { CustomIcon } from '@components';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';

import styles from './MenuTab.module.scss';
import { MenuTabDropDown } from './MenuTabDropDown';
import { MenuTabMore } from './MenuTabMore';
import { MenuPageContext } from 'plugins/MenuPlugin/pages/MenuPage/common/MenuPageContext';
import { setTimeout } from 'timers';
import { transformCategoryHashUrl } from 'core/core/helpers/transformCategoryHashUrl';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';
import { MOBILE_HEADER_OFFSET } from 'plugins/MenuPlugin/pages/MenuPage/common/constants';

export const MenuTab: React.FC = observer(() => {
  const { selectedTab, setSelectedTab, isLoading } =
    useContext(MenuPageContext);
  const MenuStore = useMenuStore();
  const getTranslationForItem = useGetTranslationForMenuItem();
  const location = useLocation();
  const windowSize = useWindowSize();
  const navigation = useNavigation();
  const menuTabRef = useRef<HTMLDivElement | null>(null);

  const [menuTabCategories, setMenuTabCategories] = useState<
    Array<MenuCategoryType | MenuCategoryType[]>
  >([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownMainCategory, setDropdownMainCategory] = useState(undefined);
  const [dropdownLeft, setDropdownLeft] = useState(0);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [tabsRefCurrentAll, setTabsRefCurrentAll] = useState([]);
  const tabsRef = useRef<Array<HTMLDivElement | null>>([]);

  const changeUrlOnClick = (category: MenuCategoryType) => {
    const isSameCategory = category.id === selectedTab;
    const verticalOffset = isSameCategory ? 0 : MOBILE_HEADER_OFFSET;
    navigation.replaceHash(transformCategoryHashUrl(category.name, 'encode'));
    window.scrollTo(window.scrollX, window.scrollY - verticalOffset);
    setTimeout(() => setSelectedTab(category.id), 100);
  };

  const onCategoryClick = useCallback(
    (category) => {
      if (category.subcategories && category.subcategories.length > 0) {
        setDropdownMainCategory(category);
        setDropdownOpen(!dropdownOpen);

        const tabWidth = document
          .getElementById(category.id.toString())
          .getBoundingClientRect().width;
        const tabLeft = document
          .getElementById(category.id.toString())
          .getBoundingClientRect().x;

        setDropdownWidth(tabWidth);
        setDropdownLeft(tabLeft);
      } else {
        setDropdownOpen(false);
      }

      if (
        !category.subcategories ||
        !category.subcategories.some(
          (sc: MenuSubcategory) => sc.id === selectedTab,
        )
      ) {
        setSelectedTab(category.id);
        changeUrlOnClick(category);
      }

      GaService.instance.sendEvent({
        category: 'Ordering - Menu',
        action: 'Click on category',
        label: category.name,
      });
    },
    [dropdownOpen, selectedTab],
  );

  const onMoreCategoryClick = useCallback(
    (category) => {
      setSelectedTab(category.id);
      changeUrlOnClick(category);
      GaService.instance.sendEvent({
        category: 'Ordering - Menu',
        action: 'Click on category',
        label: category.name,
      });

      setDropdownOpen(false);
    },
    [dropdownOpen, selectedTab],
  );

  const handleHorizontalTabMenuScroll = (
    selectedTabElement: HTMLDivElement,
  ) => {
    menuTabRef.current.scrollTo({
      left:
        selectedTabElement.offsetLeft -
        (menuTabRef.current.offsetWidth - selectedTabElement.offsetWidth) / 2,
      behavior: 'smooth',
    });
  };

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const primary = get(_config, 'palette.primary', '');

    return {
      primary,
    };
  }, [location.search]);

  useEffect(() => {
    if (tabsRef.current && tabsRef.current.length > 0) {
      tabsRef.current = tabsRef.current.slice(
        0,
        MenuStore.categoriesAndSubcategoriesFlat.length,
      );
    }
  }, [MenuStore.categoriesAndSubcategoriesFlat]);

  useEffect(() => {
    if (tabsRef.current && tabsRef.current.length > 0) {
      let foundIndex = MenuStore.menu.findIndex((e) => e.id === selectedTab);
      if (foundIndex === -1) {
        const parent = MenuStore.menu.filter((item) => {
          const subcategory = item.subcategories.filter(
            (sub) => sub.id === selectedTab,
          );

          return subcategory.length > 0;
        });

        foundIndex =
          parent.length > 0
            ? MenuStore.menu.findIndex((e) => e.id === parent[0].id)
            : 0;
      }

      if (!windowSize.isLg && menuTabRef.current) {
        handleHorizontalTabMenuScroll(tabsRef.current[foundIndex]);
      }
    }
  }, [selectedTab]);

  useLayoutEffect(() => {
    if (isLoading) {
      return;
    }

    if (menuTabCategories.length === 0 || !windowSize.isLg) {
      setMenuTabCategories(MenuStore.menu);
    }

    if (
      windowSize.isLg &&
      tabsRefCurrentAll.length > 0 &&
      menuTabCategories.length > 0
    ) {
      const menuRoot = document
        .getElementById('menu-desktop-root')
        .getBoundingClientRect();
      const menuTabsCounter = tabsRefCurrentAll.filter(
        (tr) => tr && tr.rect.right + tr.rect.width < menuRoot.width,
      ).length;

      setMenuTabCategories([
        ...MenuStore.menu.slice(0, menuTabsCounter),
        MenuStore.menu.slice(menuTabsCounter, MenuStore.menu.length),
      ]);
    }
  }, [windowSize.width, MenuStore.menu, tabsRefCurrentAll, isLoading]);

  useEffect(() => {
    if (tabsRefCurrentAll.length === 0) {
      setTabsRefCurrentAll(
        tabsRef.current.length > 0 &&
          tabsRef.current.length === MenuStore.menu.length
          ? tabsRef.current.map((tr) => {
              return { rect: tr.getBoundingClientRect() };
            })
          : [],
      );
    }
  }, [tabsRef.current.length, MenuStore.menu]);

  const renderCategory = (
    category: MenuCategoryType | MenuCategoryType[],
    index: number,
  ) => {
    if (!Array.isArray(category)) {
      const isSelected =
        category.id === selectedTab ||
        category.subcategories.some((sc) => sc.id === selectedTab);
      const title = textEllipsis(
        getTranslationForItem(category.pos_unique_id, category).name,
        22,
      );

      return (
        <div
          onClick={() => onCategoryClick(category)}
          id={category.id.toString()}
          key={category.id}
          ref={(el) => (tabsRef.current[index] = el)}
        >
          <div
            className={classnames(
              styles.menuTabLable,
              isSelected ? styles.menuTabSelected : '',
            )}
            style={isSelected ? { backgroundColor: config.primary } : {}}
          >
            {title}
            {category.subcategories && category.subcategories.length > 0 && (
              <CustomIcon
                name={'arrowDown'}
                className={classnames(
                  styles.menuTabLableArrow,
                  styles.menuTabLableArrowDown,
                  isSelected && dropdownOpen
                    ? styles.menuTabLableArrowDownOpen
                    : '',
                )}
                style={!isSelected ? { color: '#1D1B20' } : {}}
              />
            )}
          </div>
        </div>
      );
    }

    if (category.length > 0) {
      return (
        <MenuTabMore
          key="menu-tab-more"
          categories={category}
          selectedTabId={selectedTab}
          onCategoryClick={onMoreCategoryClick}
          onMoreClick={() => {
            setDropdownOpen(false);
          }}
          forceClose={dropdownOpen}
        />
      );
    }
  };

  return (
    <div
      ref={menuTabRef}
      className={styles.menuTab}
      style={dropdownOpen ? { overflowX: 'hidden' } : {}}
    >
      {menuTabCategories.map(renderCategory)}
      {dropdownOpen && (
        <MenuTabDropDown
          tabLeft={dropdownLeft}
          tabWidth={dropdownWidth}
          category={dropdownMainCategory}
          selectedId={selectedTab}
          onClick={(category: MenuSubcategory | MenuCategoryType) =>
            onCategoryClick(category)
          }
        />
      )}
    </div>
  );
});

MenuTab.displayName = 'MenuTab';
