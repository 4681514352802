import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
} from 'react';

import classnames from 'classnames';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  GaService,
  MyCheckApp,
  removeEmojies,
  useAuthStore,
  useCheckoutStore,
  useErrorTrackerHandler,
  useLocationStore,
  useMenuStore,
  useNavigation,
  useParams,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import {
  Background,
  FieldLimitLabel,
  Header,
  Input,
  Loader,
  Overlay,
  Paper,
} from '@components';

import { CustomButton } from '../../../../components/CustomButton/CustomButton';
import { currencyFormatter } from '../../../../core/core/helpers/currencyFormatter';
import { AddonsGroup } from '../../components/AddonsGroup/AddonsGroup';
import { KcalLAdultLabel } from '../../components/KcalLAdultLabel/KcalLAdultLabel';
import { ProductInfo } from '../../components/ProductInfo/ProductInfo';
import { Quantity } from '../../components/Quantity/Quantity';
import { QuantityLimitedModal } from '../../components/QuantityLimitedModal/QuantityLimitedModal';
import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';

import styles from './ItemPage.module.scss';
import { DynamicValueArray, GenericRefProps } from 'types/GenericTypes';
import { ProductItem } from 'plugins/CheckoutPlugin/types/CheckoutTypes';

let isSubmit = false;

interface Props {
  id?: string;
  editId?: string;
  onClose?: () => void;
}

export const ItemPage: React.FC<Props> = observer(({ id, editId, onClose }) => {
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const AuthStore = useAuthStore();
  const MenuStore = useMenuStore();
  const currentCurrency = LocationStore.currenciesList.find(
    (item) => item.code === LocationStore.selectedRestaurant.currency,
  );

  const [enableValidation, setEnableValidation] = useState(false);
  const [quantityLimitModal, setQuantityLimitModal] = useState(false);
  const viewMode = LocationStore.viewMode;

  const errorHandler = useErrorTrackerHandler();
  const navigation = useNavigation();
  const params = useParams<{ id: string; editId: string }>();

  const itemsRef = useRef<Array<GenericRefProps>>([]);

  const _editId = params.editId || editId;
  const itemData: ProductItem =
    CheckoutStore.currentOrder[_editId] ||
    ((MenuStore.items.find(
      (item) => item.id.toString() === (params.id || id),
    ) || {}) as unknown as ProductItem);

  const [quantity, setQuantity] = useState(itemData.quantity || 1);
  const [comment, setComment] = useState(itemData.comment || '');
  const getTranslationForItem = useGetTranslationForMenuItem();

  const accessToken = AuthStore.accessToken;
  const experienceId = LocationStore.selectedExperienceId;
  const businessId = LocationStore.selectedRestaurantId;

  const location = useLocation();
  const isShowCommentFromExperience =
    LocationStore.selectedExperience?.settings?.ui?.menu?.items
      ?.show_comments ?? true;

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const quantityLimit = get(_config, 'settings.itemLimitation', 100);
    const isShowComment = get(_config, 'settings.showComment', false);
    const isCommentVisible = isShowComment && isShowCommentFromExperience;
    const additionalTitleStyle = get(_config, 'general.subTitle', {});
    const primary = get(_config, 'palette.primary', {});

    return {
      quantityLimit,
      isCommentVisible,
      additionalTitleStyle,
      primary,
    };
  }, [location.search]);

  const itemName = getTranslationForItem(itemData.pos_unique_id, itemData).name;

  const validate = ({ modifiers }: FormikValues) => {
    let elementToScroll = null;
    const setElementToScroll = (modifierGroup) => {
      if (!elementToScroll) {
        elementToScroll = modifierGroup.id;
      }
    };

    const errors = {};
    for (let i = 0; i < itemData.modifierGroups.length; i++) {
      const modifierGroup = itemData.modifierGroups[i];
      const value = modifiers[modifierGroup.id] || [];

      if (!value && modifierGroup.minSelect > 0) {
        errors[modifierGroup.id] = 'ERROR';
        setElementToScroll(modifierGroup);
      }

      if (
        modifierGroup.minSelect > 0 &&
        modifierGroup.minSelect > value.length
      ) {
        errors[modifierGroup.id] = 'ERROR';
        setElementToScroll(modifierGroup);
      }

      if (
        modifierGroup.maxSelect > 0 &&
        value.length > modifierGroup.maxSelect
      ) {
        errors[modifierGroup.id] = 'ERROR';
        setElementToScroll(modifierGroup);
      }
    }

    const keysErrors = Object.keys(errors);
    if (keysErrors.length) {
      if (isSubmit) {
        itemsRef.current
          .find((e) => e.getId() === elementToScroll)
          .scrollIntoView();
      }

      isSubmit = false;
      return { modifiers: errors };
    }

    return null;
  };

  const isOverLimit = () => {
    return (
      config.quantityLimit >=
      Object.values(CheckoutStore.currentOrder).reduce(
        (acc, e) => acc + (_editId === e.editId ? 0 : e.quantity),
        0,
      ) +
        quantity
    );
  };

  const calculateTotalPrice = (selectedModifiers = {}) => {
    const totalPrice = Object.keys(selectedModifiers)
      .map((groupId) =>
        itemData.modifierGroups
          .find((group) => group.id === Number(groupId))
          .modifiers.filter((mod) =>
            selectedModifiers[groupId].find(
              (el: string) => el === mod.pos_unique_id,
            ),
          ),
      )
      .flat()
      .reduce(
        (acc: number, { price: modPrice }: { price: number }) => acc + modPrice,
        itemData.price,
      );

    return totalPrice * quantity;
  };

  const onSubmit = async ({ modifiers }) => {
    try {
      if (isOverLimit()) {
        CheckoutStore.setOrderItem({
          ...itemData,
          modifiers,
          quantity,
          comment,
        });
        await CheckoutStore.updateOrCreateCheckout({
          accessToken,
          experienceId,
          businessId,
        });

        GaService.instance.sendEvent({
          category: 'Ordering - Edit Item',
          action: 'Add to my order',
          label: itemData.name,
          value: calculateTotalPrice(modifiers),
        });

        if (onClose) {
          onClose();
        } else {
          navigation.goBack();
        }
      } else {
        setQuantityLimitModal(true);
      }
    } catch (err) {
      if (err instanceof Error) {
        errorHandler.onError(err, () =>
          navigation.replace(
            isLg
              ? '/menu'
              : CheckoutStore.numberOfCurrentOrderItems
                ? '/menu/order'
                : '/menu',
          ),
        );
      }
    }
  };

  const onRemoveItem = async () => {
    try {
      CheckoutStore.removeOrderItem(_editId);
      await CheckoutStore.updateOrCreateCheckout({
        accessToken,
        experienceId,
        businessId,
      });

      GaService.instance.sendEvent({
        category: 'Ordering - Edit Item',
        action: 'Remove from my order',
        label: itemData.name,
      });
      if (isLg) {
        navigation.goBack();
      } else {
        navigation.replace(
          CheckoutStore.numberOfCurrentOrderItems ? '/menu/order' : '/menu',
        );
      }
    } catch (err) {
      if (err instanceof Error) {
        errorHandler.onError(err);
      }
    }
  };

  const handleCloseQuantityLimited = () => setQuantityLimitModal(false);
  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => setQuantity(quantity - 1);
  const changeComment = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value, maxLength } = e.target;
    const commentValue = value.slice(0, maxLength);

    setComment(removeEmojies(commentValue));
  };

  useEffect(() => {
    if (isLg) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (isLg) {
        document.body.removeAttribute('style');
      }
    };
  }, []);

  const renderItemPageActivity = () => (
    <div className={styles.itemPageFormFooterQuantity}>
      <Quantity
        quantity={quantity}
        decreaseQuantity={decreaseQuantity}
        increaseQuantity={increaseQuantity}
      />
      {!!_editId && (
        <div
          data-test-id="remove-item"
          onClick={onRemoveItem}
          className={styles.itemPageFormFooterRemove}
        >
          {t('menu.removeItem')}
        </div>
      )}
    </div>
  );

  const renderAdditionalTitle = () =>
    !isLg &&
    !LocationStore.isSelectedExperienceActive && (
      <p style={config.additionalTitleStyle} className={styles.additionalTitle}>
        {t('menu.viewMenuSubTitle')}
      </p>
    );

  const renderBody = () => {
    return (
      <Background className={classnames(styles.itemPage)} secondary>
        {MenuStore.isFetching && !Object.keys(itemData).length ? (
          <Loader withOverlay={false} />
        ) : (
          <>
            <Header
              headerTitle={itemName}
              enforceSmall
              additionalTitle={renderAdditionalTitle()}
              rootStyle={{
                backgroundColor: config.primary,
                color: '#ffffff',
                position: 'fixed',
                width: isLg ? 480 : '100%',
              }}
              color="#ffffff"
              handleBackClick={
                onClose
                  ? () => {
                      onClose();
                    }
                  : undefined
              }
            />

            <div
              className={styles.scroll}
              style={
                isLg
                  ? !!_editId
                    ? { height: 'calc(100% - 203px' }
                    : { height: 'calc(100% - 170px)' }
                  : {}
              }
            >
              {!!itemData.image && (
                <div
                  className={classnames(styles.itemPageImage, {
                    [styles.itemPageImageIsNative]: LocationStore.isNative,
                  })}
                  style={{ backgroundImage: `url(${itemData.image})` }}
                />
              )}
              <ProductInfo itemData={itemData} />
              <Formik
                initialValues={{ modifiers: itemData.modifiers }}
                onSubmit={onSubmit}
                validate={validate}
                validateOnChange={enableValidation}
                validateOnBlur={enableValidation}
              >
                {(formikProps) => (
                  <Form
                    onSubmit={(e) => {
                      isSubmit = true;
                      setEnableValidation(true);
                      return formikProps.handleSubmit(e);
                    }}
                    className={classnames(styles.itemPageForm)}
                  >
                    {itemData.modifierGroups?.map((modifierGroup, index) => (
                      <AddonsGroup
                        ref={(el) => (itemsRef.current[index] = el)}
                        key={modifierGroup.pos_unique_id}
                        formikProps={
                          formikProps as unknown as FormikProps<DynamicValueArray>
                        }
                        itemData={modifierGroup}
                      />
                    ))}
                    {config.isCommentVisible && (
                      <>
                        <div className={styles.itemPageFormLabel}>
                          {t('menu.comment')}
                        </div>
                        <Paper className={styles.itemPageFormCard}>
                          <Background className={styles.flex}>
                            <Input
                              type="text"
                              rows={2}
                              value={comment}
                              onChange={changeComment}
                              className={styles.itemPageFormCardInput}
                              style={{ border: 'none' }}
                              placeholder={t('menu.commentPlaceHolder')}
                              testId="menu-item-comment"
                              maxLength={
                                LocationStore.uiMenuSettings?.items?.comments
                                  ?.max
                              }
                            />
                          </Background>
                        </Paper>
                        <FieldLimitLabel
                          maxLength={
                            LocationStore.uiMenuSettings?.items?.comments?.max
                          }
                          fieldValue={comment}
                        />
                      </>
                    )}

                    <KcalLAdultLabel
                      className={isLg ? styles.itemPageFormKcal : ''}
                    />
                    <div
                      className={styles.itemPageFormFooter}
                      style={!!_editId ? { height: 163 } : {}}
                    >
                      {renderItemPageActivity()}
                      <CustomButton
                        testId="add-item"
                        type="submit"
                        text={
                          _editId
                            ? t('menu.itemDetails.updateOrder', {
                                price: currencyFormatter(
                                  calculateTotalPrice(
                                    formikProps.values.modifiers,
                                  ),
                                  currentCurrency,
                                ),
                              })
                            : viewMode
                              ? t('menu.addToListMenu')
                              : t('menu.itemDetails.addToOrder', {
                                  price: currencyFormatter(
                                    calculateTotalPrice(
                                      formikProps.values.modifiers,
                                    ),
                                    currentCurrency,
                                  ),
                                })
                        }
                        backgroundColor="#1D1B20"
                        className={styles.itemPageFormFooterCustomButton}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
              {quantityLimitModal && (
                <QuantityLimitedModal
                  handleClose={handleCloseQuantityLimited}
                />
              )}
            </div>
          </>
        )}
      </Background>
    );
  };

  if (isLg) {
    return (
      <Overlay testId="menu-item-modal" height={779} width={480}>
        {renderBody()}
      </Overlay>
    );
  }

  return (
    <Overlay
      testId="menu-item-modal"
      paperStyle={{
        maxHeight: 'none',
        maxWidth: 'none',
      }}
    >
      {renderBody()}
    </Overlay>
  );
});

ItemPage.displayName = 'ItemPage';
