import React, { useMemo, type PropsWithChildren } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import styles from './KioskButton.module.scss';
import { CustomIcon } from '@components';
import { IconSvg } from 'components/Icon/helpers';

interface PrimaryButtonProps extends PropsWithChildren {
  onClick?: () => void;
  className?: string;
  iconName?: IconSvg;
  testId?: string;
  withTransparentBG?: boolean;
  disabled?: boolean;
}

export const KioskButton: React.FC<PrimaryButtonProps> = ({
  children,
  onClick,
  className,
  iconName,
  testId = '',
  withTransparentBG = false,
  disabled = false,
}) => {
  const location = useLocation();
  const configStyles = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const primaryColor = get(_config, 'palette.primary', '');

    return {
      primaryColor,
    };
  }, [location.search]);

  return (
    <button
      onClick={onClick}
      className={classnames(styles.button, { [className]: !!className })}
      style={{
        color: withTransparentBG ? configStyles.primaryColor : 'white',
        border: `1px solid ${configStyles.primaryColor}`,
        backgroundColor: withTransparentBG
          ? 'transparent'
          : configStyles.primaryColor,
        ...(disabled && { opacity: '0.6' }),
      }}
      data-test-id={`button-${testId}`}
      disabled={disabled}
    >
      {iconName && (
        <CustomIcon
          name={iconName}
          style={{ color: configStyles.primaryColor, width: '16' }}
        />
      )}
      {children}
    </button>
  );
};
