import React from 'react';
import { CustomButton, PriceFormatter } from '@components';
import styles from '../../KioskCheckout.module.scss';
import { useTranslation } from 'mycheck-core';
import { useOutletContext } from 'react-router-dom';
import { KioskOutletContext } from 'components/Kiosk/types';

export const KioskCheckoutTapBody = () => {
  const { t } = useTranslation();
  const { cancelTransaction, obfuscatedEmailReceipt, total } =
    useOutletContext<
      Pick<
        KioskOutletContext,
        'cancelTransaction' | 'obfuscatedEmailReceipt' | 'total'
      >
    >();
  return (
    <>
      {obfuscatedEmailReceipt && (
        <h3 className={styles.emailTitle}>
          {t('kiosk.kioskCheckoutBody.emailTitle')}&nbsp;
          {obfuscatedEmailReceipt}
        </h3>
      )}
      <h1 className={styles.title}>
        {t('kiosk.kioskCheckoutBody.title')}&nbsp;
        <PriceFormatter value={Number(total)} withoutSpace />
      </h1>
      <h2 className={styles.subtitle}>
        {t('kiosk.kioskCheckoutTapBody.subtitle')}
      </h2>
      <img
        alt="tapPayment"
        className={styles.tapImage}
        src="https://static-content.mycheckapp.com/pickupv2/updated_assets/tapPayment.jpg"
      />
      <CustomButton
        className={styles.button}
        text={t('kiosk.kioskCheckoutTapBody.secondaryButton')}
        testId="kiosk-checkout-tap-button"
        isBorderColor
        handleButtonClick={cancelTransaction}
      />
    </>
  );
};
