import React, { useCallback, useEffect, useState } from 'react';
import useIdleTimer from 'core/hooks/useIdleTimer';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useAppSelector } from '../../../core/core/store/store';
import { getKioskStatus } from '../../../core/core/store/kiosk/slice';
import { get } from 'lodash';
import { MyCheckApp } from '../../../core/core/core';
import { KioskModal } from '@components';
import {
  useCheckoutStore,
  useKioskReactNativeEvent,
  useLocation,
  useTranslation,
} from 'mycheck-core';
import { matchPath } from 'react-router-dom';
import { IdleTimeoutType } from './types';
import { AutoRedirectTimeoutSeconds, idleRoutingConfig } from './constants';
import { KioskPostMessageEvent } from 'types/KioskTypes';

const KioskIdleService: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [idleTimeoutType, setIdleTimeoutType] = useState<IdleTimeoutType>(
    IdleTimeoutType.None,
  );
  const location = useLocation();
  const CheckoutStore = useCheckoutStore();
  const [isExpiredModalOpen, setExpiredModalOpen] = useState(false);
  const isKioskMode = useAppSelector(getKioskStatus);
  const { sentReactNativeEvent } = useKioskReactNativeEvent(
    KioskPostMessageEvent.RedirectToLandingPage,
    () => setExpiredModalOpen(false),
  );
  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const kioskFlow = get(_config, 'settings.kioskFlow', '');
    return {
      kioskFlow,
    };
  }, [window.location.search]);

  const kioskConfigRedirectTime = config.kioskFlow?.autoRedirect;

  const getCurrentPageIdleTimeoutType = useCallback((): IdleTimeoutType => {
    const type = Object.entries(idleRoutingConfig).find(([, routes]) =>
      routes.some((route) => matchPath({ path: route }, location.pathname)),
    );
    return type ? (type[0] as IdleTimeoutType) : IdleTimeoutType.None;
  }, [location.pathname]);

  useEffect(() => {
    if (isKioskMode && kioskConfigRedirectTime) {
      setIdleTimeoutType(getCurrentPageIdleTimeoutType());
    }
  }, [getCurrentPageIdleTimeoutType, isKioskMode, kioskConfigRedirectTime]);

  const toogleExpiredModalOpen = () => {
    setExpiredModalOpen((prevProps) => !prevProps);
  };

  const redirectToKioskLandingPage = () => {
    CheckoutStore.removeAllOrderItems();
    sentReactNativeEvent();
  };

  useIdleTimer({
    enable: idleTimeoutType === IdleTimeoutType.Auto,
    timeout: AutoRedirectTimeoutSeconds * 1000,
    onTimeout: redirectToKioskLandingPage,
  });

  const inactivityUserIdleTimer = useIdleTimer({
    enable: idleTimeoutType === IdleTimeoutType.Modal,
    timeout: kioskConfigRedirectTime * 1000,
    onTimeout: toogleExpiredModalOpen,
  });

  const moreTimeModalIdleTimer = useIdleTimer({
    enable: isExpiredModalOpen,
    timeout: AutoRedirectTimeoutSeconds * 1000,
    onTimeout: redirectToKioskLandingPage,
    resetTimerOnUserActivity: false,
  });

  const handleMoreTimeClick = () => {
    moreTimeModalIdleTimer.reset();
    toogleExpiredModalOpen();
    inactivityUserIdleTimer.start();
  };

  return (
    <>
      {children}
      {isExpiredModalOpen && (
        <KioskModal
          title={t('kiosk.kioskIdleTimeoutModal.title')}
          withCenterTitle
          primaryButtonText={t('kiosk.kioskIdleTimeoutModal.primaryButton')}
          secondaryButtonText={t('kiosk.kioskIdleTimeoutModal.secondaryButton')}
          primaryButtonClick={handleMoreTimeClick}
          secondaryButtonClick={redirectToKioskLandingPage}
          testId="kiosk-idle-timeout-modal"
        >
          {t('kiosk.kioskIdleTimeoutModal.content', {
            remainingTime: moreTimeModalIdleTimer.remainingTime / 1000,
          })}
        </KioskModal>
      )}
    </>
  );
};

export default KioskIdleService;
