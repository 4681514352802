import { KioskPostMessageEvent } from 'types/KioskTypes';

export function useKioskReactNativeEvent<
  CallbackType extends (...args: unknown[]) => void,
>(
  eventName: KioskPostMessageEvent,
  callback?: CallbackType,
  successCallback?: CallbackType,
) {
  const sentReactNativeEvent = () => {
    try {
      window.ReactNativeWebView.postMessage(eventName);
      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      console.error(KioskPostMessageEvent.Error);
      if (callback) {
        callback();
      }
    }
  };
  return { sentReactNativeEvent };
}
