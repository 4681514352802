import dayjs from 'dayjs';
import { decode } from 'he';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { StorageService } from 'mycheck-core';
import {
  MenuCategoryType,
  MenuItem,
  MenuModifier,
  MenuModifierGroup,
  MenuSubcategory,
} from 'types/GenericTypes';

import { MenuApi } from './MenuApi';
import { MenuItemType } from './types/MenuItemType';

type CurrentMenuType = {
  menus: any;
  translations: any;
  time: string;
  timezone: string;
  language: string;
  bid: number;
};

type MenuTranslationValues = { name: string; description?: string };
type MenuTranslation = Record<string, MenuTranslationValues>;

export class MenuStore {
  @observable menu: Array<MenuCategoryType> = [];
  @observable menuStamp: number = null;
  @observable @persist('list') outOfStockItems: any = [];
  @observable @persist('object') translations: any = {};
  @observable menuCache: any = null;
  @observable isFetching = false;
  @observable viewOnlyShowed = false;

  private menuApi: MenuApi;

  constructor(menuApi: MenuApi) {
    this.menuApi = menuApi;

    makeAutoObservable(this);
  }

  @computed
  get items() {
    return this.menu
      .map((element) => [
        ...(element?.items || []),
        ...(element.subcategories
          ?.map((subcategory) => subcategory?.items || [])
          .reduce((a, b) => [...a, ...b], []) || []),
      ])
      .reduce((a, b) => [...a, ...b], []);
  }

  @computed
  get categoriesAndSubcategoriesFlat(): Array<
    MenuCategoryType & MenuSubcategory
  > {
    return this.menu.map((e) => [e, ...e.subcategories]).flat() as Array<
      MenuCategoryType & MenuSubcategory
    >;
  }

  @computed
  get menuFlat(): Array<MenuCategoryType & MenuSubcategory> {
    return this.menu
      .map((e) => [
        e,
        ...(e.items || []),
        ...e.subcategories
          .map((subCat) => [subCat, ...(subCat.items || [])])
          .flat(),
      ])
      .flat() as Array<MenuCategoryType & MenuSubcategory>;
  }

  @computed
  get flatMenu() {
    return this.menu
      .map((element) => [
        element,
        ...(element?.items || []),
        ...(element.subcategories
          ?.map((subcategory) => [subcategory, ...(subcategory?.items || [])])
          .reduce((a, b) => [...a, ...b], []) || []),
      ])
      .reduce((a, b) => [...a, ...b], []);
  }

  transformModifiers = (item: any): MenuModifier => ({
    id: item.id,
    bid: item.bid,
    serial_id: item.serial_id,
    pos_unique_id: item.pos_unique_id,
    stock: item.available_stock,
    name: decode(item.Name || ''),
    allergens: item.allergens || [],
    price: item.Price,
    under_18: item.under_18,
    type: MenuItemType.MODIFIER,
    kcal: item.calories ? Number(Number(item.calories).toFixed(0)) : 0,
  });

  transformModifierGroups = (item: any): MenuModifierGroup => ({
    id: item.id,
    bid: item.bid,
    pos_unique_id: item.pos_unique_id,
    serial_id: item.serial_id,
    name: decode(item.Name || ''),
    modifiers: item.Modifiers?.map(this.transformModifiers) || [],
    minSelect: item.MinSelect,
    maxSelect: item.MaxSelect,
    under_18: item.under_18,
    type: MenuItemType.MODIFIER_GROUP,
    kcal: item.calories ? Number(Number(item.calories).toFixed(0)) : 0,
  });

  transformItem = (item: any): MenuItem => ({
    id: item.id,
    bid: item.bid,
    pos_unique_id: item.pos_unique_id,
    serial_id: item.serial_id,
    allergens: item.allergens || [],
    image: item.photos,
    name: decode(item.Name || ''),
    description: decode(item.Description || ''),
    price: item.Price,
    saleIds: item.app_sale_ids,
    saleText: item.app_sale_text,
    modifierGroups:
      item.ModifierGroups?.map(this.transformModifierGroups) || [],
    modifiers: {} as MenuModifier,
    stock: !this.outOfStockItems.includes(item.serial_id),
    under_18: item.under_18,
    is_hidden: item.is_hidden,
    type: MenuItemType.ITEM,
    kcal: item.calories ? Number(Number(item.calories).toFixed(0)) : 0,
  });

  transformSubcategories = (subcategory: any): MenuSubcategory => ({
    id: subcategory.id,
    bid: subcategory.bid,
    serial_id: subcategory.serial_id,
    name: decode(subcategory.Name || ''),
    pos_unique_id: subcategory.pos_unique_id,
    under_18: subcategory.under_18,
    items:
      subcategory.Classes?.map(this.transformSubcategories) ||
      subcategory.Items?.map(this.transformItem),
    type: MenuItemType.SUBCATEGORY,
  });

  transformCategories = (category: any): MenuCategoryType => ({
    id: category.id,
    bid: category.bid,
    serial_id: category.serial_id,
    name: decode(category.Name || ''),
    pos_unique_id: category.pos_unique_id,
    under_18: category.under_18,
    subcategories: category.Classes?.map(this.transformSubcategories) || [],
    items: category.Items?.map(this.transformItem) || [],
    type: MenuItemType.CATEGORY,
  });

  transformTranslationValue = (translation: MenuTranslationValues) => ({
    name: decode(translation.name),
    ...(translation.description && {
      description: decode(translation.description),
    }),
  });

  trasformTranslation = (menuTranslation: MenuTranslation) =>
    Object.keys(menuTranslation).reduce((acc, key) => {
      return {
        ...acc,
        [key]: this.transformTranslationValue(menuTranslation[key]),
      };
    }, {});

  getCurrentMenu = (menus: any, time: string, timezoneName: string) => {
    const now = dayjs(time).tz(timezoneName);

    let currentMenu = menus.filter(({ day, from, to }) => {
      if (!from || !to) {
        return false;
      }

      if (day != now.day()) {
        return false;
      }

      const fromTime = dayjs
        .tz(`${from}`, 'HH:mm', timezoneName)
        .year(now.year())
        .month(now.month())
        .date(now.date());
      const toTime = dayjs
        .tz(`${to}`, 'HH:mm', timezoneName)
        .year(now.year())
        .month(now.month())
        .date(now.date());
      return now.isBetween(fromTime, toTime, null, '[)');
    })[0];

    if (!currentMenu) {
      currentMenu = find(menus, { is_default: true });
    }

    return currentMenu;
  };

  @action
  clearMenu = () => {
    this.menu = [];
    this.menuStamp = null;
    this.outOfStockItems = [];
    this.translations = {};
    this.menuCache = null;
    this.viewOnlyShowed = false;
  };

  @action
  startFetching = (): void => {
    this.isFetching = true;
  };

  @action
  stopFetching = (): void => {
    this.isFetching = false;
  };

  @action
  setViewOnlyShowed = (show: boolean): void => {
    this.viewOnlyShowed = show;
  };

  @action
  fetchMenuWithUrl = async (url: string) => {
    const menu = await this.menuApi.fetchMenuWithUrl(url);
    this.menu = menu.map(this.transformCategories);
    this.menuStamp = Date.now();
  };

  @action
  fetchMenuTranslationWithUrl = async (language: string, url: string) => {
    try {
      const translation = await this.menuApi.fetchMenuWithUrl(url);
      this.translations[language] = this.trasformTranslation(translation);
    } catch (err) {}
  };

  @action
  fetchOutOfStockItems = async (bid: number) => {
    try {
      const { items } = await this.menuApi.fetchOutOfStockItems(bid);
      this.outOfStockItems = items;
    } catch (err) {}
  };

  @action
  selectCurrentMenu = async ({
    bid,
    menus,
    translations,
    time,
    timezone,
    language,
  }: CurrentMenuType) => {
    const currentMenu = this.getCurrentMenu(menus, time, timezone);
    if (!isEqual(this.menuCache, currentMenu) && currentMenu) {
      await this.fetchOutOfStockItems(bid).finally(
        async () => await this.fetchMenuWithUrl(currentMenu.url),
      );
      this.menuCache = currentMenu;
    }

    if (translations[language] && !this.translations[language]) {
      await this.fetchMenuTranslationWithUrl(language, translations[language]);
    }

    if (this.isFetching) {
      this.isFetching = false;
    }
  };

  @action
  setIsAdult = () => {
    StorageService.setValue(StorageService.STORAGE_KEYS.IS_ADULT, 'true');
  };

  @action
  getIsAdult = () => {
    return (
      Boolean(StorageService.getValue(StorageService.STORAGE_KEYS.IS_ADULT)) ||
      false
    );
  };

  @action
  clearIsAdult = () => {
    StorageService.removeValue(StorageService.STORAGE_KEYS.IS_ADULT);
  };
}
